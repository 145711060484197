const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
        path: "/About",
        exact: true,
        component: "About"
    },
];

export default routes;
